<template>
  <div class="user-material">
    <van-tabs v-model="active" color="#FF7226" title-active-color="#FF7226" @change="getTabContent">
      <van-tab v-for="item in tabList" :key="item.id" :title="item.name" :name="item.id">
        <DefaultPage v-if="tabContent[item.id] && tabContent[item.id].length == 0" style="padding-top: 200px;" />
        <div v-else class="list">
          <div v-for="it in tabContent[item.id]" :key="it.id" class="item">
            <img :src="it.imgName" alt="">
          </div>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
import { getAllByCategory, getFirstNewMaterialImg } from '@/api/product'
import DefaultPage from '@/components/DefaultPage'
import { getOssUrl } from '@/utils/oss'
export default {
  components: { DefaultPage },
  data() {
    return {
      active: '',
      tabList: [],
      tabContent: {}
    }
  },
  mounted() {
    this.getTabList()
  },
  methods: {
    async getTabList() {
      const loading = this.$toast.loading({ forbidClick: true, duration: 0 })
      const res = await getAllByCategory({ category: 'demand_material' })
      this.tabList = res.datas || []
      if (res.datas.length > 0) {
        this.active = this.tabList[0].id
        this.getTabContent()
      }
      loading.clear()
    },
    async getTabContent() {
      if (this.tabContent[this.active]) return
      const loading = this.$toast.loading({ forbidClick: true, duration: 0 })
      const res = await getFirstNewMaterialImg({ dictId: this.active })
      const list = res.datas || []
      for (let i = 0; i < list.length; i++) {
        list[i].imgName = await getOssUrl(list[i].imgName)
      }
      this.$set(this.tabContent, this.active, list)
      loading.clear()
    }
  }
}
</script>
